import React from "react"
import PropTypes from "prop-types"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { Link, graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const AccrocheTableau = (props) => {
  const data = useStaticQuery(graphql`
    {
      cravate: file(relativePath: { eq: "cravate.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 300)
        }
      }
      attache: file(relativePath: { eq: "attaches.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 300)
        }
      }
      crochet: file(relativePath: { eq: "crochets.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 300)
        }
      }
    }
  `)
  return (
    <>
      <SEO title="Accroche tableau" description="Système d'accroche de tableau peinture et photo pour cadre et caisse américaine" canonical={`${process.env.GATSBY_URL_ACTUEL}${props.location.pathname}`} />
      <Layout banner={false} location={props.location}>
        <div className="container">
          <h1>Accroche de tableau</h1>
          <div className="columns is-centered">
            <div className="column">
              <p>Nous proposons différents systèmes pour accrocher vos tableau.</p>
              <p>Premièrement, si vous souhaitez poser votre tableau sur une table ou un meuble, vous devez utiliser une <Link to="/accessoires/Cravate-or-271/">cravate</Link>.</p>
              <div className="mt-4 mb-4" style={{display: "flex", justifyContent: "center"}}><Link to="/accessoires/Cravate-or-271/"><GatsbyImage image={getImage(data.cravate)} alt="Cravate pour cadre" /></Link></div>
              <p>Si vous ne pouvez pas percer dans votre mur, vous pouvez utiliser des <Link to="/accessoires/Pack-de-4-attaches-adhesives-O-42mm-force-700g-348/">attaches adhésives</Link>.</p>
              <div className="mt-4 mb-4" style={{display: "flex", justifyContent: "center"}}><Link to="/accessoires/Pack-de-4-attaches-adhesives-O-42mm-force-700g-348/"><GatsbyImage image={getImage(data.attache)} alt="Attache adhésive pour cadre" /></Link></div>
              <p>Enfin, la méthode classique consiste à utiliser des <Link to="/accessoires/Pack-de-10-crochets-lourds-D-Ring-1-trou-+vis-291/">crochets de suspension</Link>. Si votre sujet est lourd, vous pouvez utiliser des modèles de <Link to="/accessoires/Pack-de-10-crochets-ultra-lourds-2-trous-+-vis-293/">crochets avec 2 trous</Link> ou 3 trous.</p>
              <div className="mt-4 mb-4" style={{display: "flex", justifyContent: "center"}}><Link to="/accessoires/Pack-de-10-crochets-lourds-D-Ring-1-trou-+vis-291/"><GatsbyImage image={getImage(data.crochet)} alt="Crochet de suspension de cadre" /></Link></div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

AccrocheTableau.propTypes = {
  identifiants: PropTypes.object,
  location: PropTypes.object,
}

export default AccrocheTableau
